/* Updated App.css */
:root {
  --background-color: rgb(7, 14, 34);
  --text-color: white;
  --primary-color: #1e90ff;
  --secondary-color: rgb(3, 7, 18);
  --box-shadow-light: 0 4px 8px blue;
  --border-radius: 8px;
  --transition-speed: 0.3s;
}

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
  color: var(--text-color);
}

a:link {
  text-decoration: none;
  color: var(--primary-color);
  padding: 2px;
  transition: color var(--transition-speed);
}

a:link:hover {
  color: #00bfff;
}

header {
  background-color: var(--background-color);
  margin: 20px;
  color: var(--text-color);
}

nav ul {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
}

nav ul li {
  margin: 0 1rem;
}

nav ul li a {
  text-decoration: none;
  color: var(--text-color);
  font-weight: bold;
  transition: color var(--transition-speed);
}

nav ul li a:hover {
  color: var(--primary-color);
}

.section, .section-about {
  background-color: var(--background-color);
  width: 95%;
  padding: 2rem;
  margin: 2rem auto;
  border-radius: var(--border-radius);
  box-shadow: 0px 1px 2px blue;
  transition: box-shadow var(--transition-speed);
}

.section-about:hover {
  box-shadow: 2px 2px 2px 2px blue;
}

input,
textarea {
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: border-color var(--transition-speed);
}

input:focus,
textarea:focus {
  border-color: var(--primary-color);
  outline: none;
}

@media (max-width: 768px) {
  nav ul {
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
  }
  nav ul li {
    margin: 0.5rem 0;
  }
}
